import nanoid from 'nanoid';
import logger from 'logger';
let storeDispatch;
export const initNotifications = (store) => {
    storeDispatch = store.dispatch;
};
export const notify = notification => {
    // TODO: create interface in notifier.js for { key, message, options, hideAction } and use it here
    if (storeDispatch) {
        storeDispatch.common.notify(notification);
    }
    else {
        logger.warn('Trying to dispatch notification to uninitialized store');
    }
};
export const error = (message, options) => ({
    message,
    key: nanoid(),
    options: Object.assign({ variant: 'error', persist: true }, options),
});
export const warning = (message, options) => ({
    message,
    key: nanoid(),
    options: Object.assign({ variant: 'warning', persist: true }, options),
});
export const success = (message, persist = false) => ({
    message,
    key: nanoid(),
    options: {
        variant: 'success',
        persist,
    },
});
export const info = (message, persist = false) => ({
    message,
    key: nanoid(),
    options: {
        variant: 'info',
        persist,
    },
});
