import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import Notifier from 'components/notifier';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import theme from 'theme';
import IntegratedApp from './integrated-app';
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
        },
        mutations: {
            retry: false, // we do retries through ky on request level
        },
    },
});
const SNACK_ANCHOR_ORIGIN = {
    horizontal: 'center',
    vertical: 'bottom',
};
const ThemedApp = () => (React.createElement(StyledEngineProvider, { injectFirst: true },
    React.createElement(ThemeProvider, { theme: theme },
        React.createElement(QueryClientProvider, { client: queryClient },
            React.createElement(SnackbarProvider, { maxSnack: 3, autoHideDuration: 5000, anchorOrigin: SNACK_ANCHOR_ORIGIN },
                React.createElement(LocalizationProvider, { dateAdapter: AdapterDateFns },
                    React.createElement(CssBaseline, null),
                    React.createElement(IntegratedApp, null),
                    React.createElement(Notifier, null),
                    React.createElement(ReactQueryDevtools, { initialIsOpen: true })))))));
export default ThemedApp;
