var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import logger from 'logger';
import * as auth from 'auth';
import _ from 'lodash';
import { success, error, notify } from './notifications';
const CODE_RE = /[?&]code=[^&]+/;
const ERROR_RE = /[?&]error=[^&]+/;
export const hasAuthParams = (searchParams = window.location.search) => CODE_RE.test(searchParams) || ERROR_RE.test(searchParams);
export const handleError = (err, message) => {
    logger.error(err);
    if (err.code === 'ETIMEDOUT') {
        notify(error('Request timed out'));
    }
    else if (err.statusCode === 401) {
        notify(error('Not authorized, log-in first'));
        auth.login();
    }
    else if (err.statusCode === 404) {
        notify(error('Path not found'));
    }
    else {
        notify(error(message || _.get(err, 'error.message', err.message) || 'Unknown error'));
    }
};
export const showSuccess = (message) => notify(success(message));
export const showError = (message) => notify(error(message));
export const paginatedQuery = ({ pageIndex, pageSize, sortBy, }) => {
    const sortObject = sortBy.reduce((all, curr) => {
        all[curr.id] = curr.desc ? 'desc' : 'asc';
        return all;
    }, {});
    return {
        limit: pageSize,
        page: pageIndex + 1,
        sort: _.isEmpty(sortObject) ? undefined : JSON.stringify(sortObject),
    };
};
export const parsePaginatedResponse = ({ pageSize }, response) => __awaiter(void 0, void 0, void 0, function* () {
    return ({
        data: yield response.json(),
        dataCount: Number(response.headers.get('x-pagination-total')),
        pageCount: Math.ceil(Number(response.headers.get('x-pagination-total')) / pageSize),
    });
});
