import { handleError } from 'utils/api';
import _ from 'lodash';
import resourcesApi from 'api/resources';
import { createSelector } from 'reselect';
import { resourcesConstants } from 'shared/constants';

const { types } = resourcesConstants;

const initialState = {
  [types.keyword]: {},
  [types.industryGroup]: {},
  [types.useCaseTemplate]: {},
};

export default {
  state: initialState,
  reducers: {
    setResources(state, { resources, resourceType }) {
      state[resourceType] = _.keyBy(resources, '_id');
      return state;
    },
    addResourceItems(state, resources) {
      const groups = _.groupBy(resources, 'resourceType');
      _.forOwn(groups, (values, resourceType) => {
        values.forEach(value => {
          state[resourceType][value._id] = value;
        });
      });
      return state;
    },
    deleteResourceItem(state, { id, resourceType }) {
      if (state[resourceType][id]) {
        delete state[resourceType][id];
      }
      return state;
    },
    updateResourceItem(state, resource) {
      state[resource.resourceType][resource._id] = resource;
      return state;
    },
  },
  effects: {
    async fetchResources(resourceType, rootState) {
      let resources = Object.values(getResource(rootState, resourceType));
      if (!resources.length) {
        try {
          resources = await resourcesApi.getAll({ resourceType });
          this.setResources({ resources, resourceType });
        } catch (e) {
          handleError(e);
        }
      }

      return resources;
    },
    async saveResources(resources) {
      try {
        const newResources = await resourcesApi.create(resources);
        this.addResourceItems(newResources);
      } catch (e) {
        handleError(e);
      }
    },
    async deleteResource({ id, resourceType }) {
      try {
        await resourcesApi.delete(id);
        this.deleteResourceItem({ id, resourceType });
      } catch (e) {
        handleError(e);
      }
    },
    async updateResource(resource) {
      try {
        const updated = await resourcesApi.update(resource._id, resource);
        this.updateResourceItem(updated);
      } catch (e) {
        handleError(e);
      }
    },
  },
};

/** ********** Dispatchers *********** */

export const fetchKeywords = d => () =>
  d.resource.fetchResources(types.keyword);
export const fetchIndustryGroups = d => () =>
  d.resource.fetchResources(types.industryGroup);
export const fetchUseCaseTemplates = d => () =>
  d.resource.fetchResources(types.useCaseTemplate);
export const saveResources = d => d.resource.saveResources;
export const updateResource = d => d.resource.updateResource;
export const deleteResource = d => (id, resourceType) =>
  d.resource.deleteResource({ id, resourceType });

/** ********** Selectors *********** */

const getResource = (s, key) => s.resource[key];
export const getKeywordsById = s => s.resource[types.keyword];
export const getKeywords = createSelector(
  getKeywordsById,
  byId => _.sortBy(Object.values(byId), k => -k.updatedAt),
);
export const getSortedKeywords = createSelector(
  getKeywordsById,
  byId => _.sortBy(Object.values(byId), 'keyword'),
);
export const getSortedKeywordsValues = createSelector(
  getSortedKeywords,
  keywords => keywords.map(k => k.keyword),
);
export const getKeywordsByName = createSelector(
  getKeywordsById,
  byId => _.keyBy(Object.values(byId), 'keyword'),
);
export const getTemplatesById = s => s.resource[types.useCaseTemplate];
export const getTemplatesValues = createSelector(
  getTemplatesById,
  byId => _.sortBy(Object.values(byId), t => -t.updatedAt),
);
export const getIndustryGroupsById = s => s.resource[types.industryGroup];
export const getIndustryGroupsValues = createSelector(
  getIndustryGroupsById,
  byId => _.sortBy(Object.values(byId), i => -i.updatedAt),
);
export const isResourceLoading = s => s.loading.effects.resource.fetchResources;
