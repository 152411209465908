import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Logo from 'images/databook-logo.svg';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    height: 170,
    overflow: 'hidden',
  },
  colored: {
    backgroundColor: theme.palette.primary.main,
    height: 90,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
  },
  logoWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closedWrapper: {
    justifyContent: 'center',
  },
  hiddenLogo: {
    display: 'none',
  },
  avatarContainer: {
    marginLeft: 'auto',
  },
  icon: {
    color: theme.palette.background.paper,
  },
  avatar: {
    width: 75,
    height: 75,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create(['width', 'height', 'margin-top'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  smallAvatar: {
    width: 30,
    height: 30,
    marginTop: theme.spacing(3),
  },
  username: {
    fontWeight: 'bold',
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen,
      delay: 250,
    }),
  },
  hiddenText: {
    opacity: 0,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  activeMenuItem: {
    backgroundColor: theme.palette.grayHover,
  },
}));

const Toolbar = ({ user, open, onClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.colored} />
      <div
        className={clsx(classes.logoWrapper, {
          [classes.closedWrapper]: !open,
        })}
      >
        <Link to='/'>
          <Logo
            style={{ width: 130 }}
            className={clsx({ [classes.hiddenLogo]: !open })}
          />
        </Link>
        <IconButton className={classes.icon} onClick={onClick} size='large'>
          {open ? <ChevronLeftIcon /> : <MenuIcon />}
        </IconButton>
      </div>
      <Avatar
        src={user.avatarUrl}
        alt='user avatar'
        className={clsx(classes.avatar, { [classes.smallAvatar]: !open })}
      />
      <Typography
        className={clsx(classes.username, { [classes.hiddenText]: !open })}
        align='center'
      >
        {user.firstName} {user.lastName}
      </Typography>
    </div>
  );
};

Toolbar.propTypes = {
  user: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default Toolbar;
