import api from './base';

const useCaseDrafts = {
  getChanges: orgId => api.get(`use-case-drafts/${orgId}`).json(),
  deleteDraft: orgId => api.delete(`use-case-drafts/${orgId}`).json(),
  saveDraft: (orgId, changes) =>
    api.put(`use-case-drafts/${orgId}`, { json: changes }).json(),
  submitChanges: (orgId, changes) =>
    api
      .put(`use-case-drafts/${orgId}/submit`, {
        json: changes,
        timeout: 18000000,
      })
      .json(),
};

export default useCaseDrafts;
