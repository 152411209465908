import api from './base';

const lambda = {
  getAll: params => api.get('lambda', { searchParams: params }),
  get: (id, options) =>
    api.get(`lambda/${id}`, { searchParams: options }).json(),
  start: (group, functionName, data) =>
    api
      .post('lambda', {
        json: { group, function: functionName, data },
      })
      .json(),
  stop: id => api.delete(`lambda/${id}/stop`).json(),
  delete: id => api.delete(`lambda/${id}`).json(),
};

export default lambda;
