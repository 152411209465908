import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import * as auth from 'auth';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'default',
    flexDirection: 'column',
  },
  text: {
    color: theme.palette.primary.contrastText,
    marginBottom: theme.spacing(2),
  },
  button: {
    borderColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.contrastText,
  },
}));

const NoAccess = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant='h3' className={classes.text}>
        No access
      </Typography>
      <Button
        classes={{ root: classes.button }}
        variant='outlined'
        onClick={() => auth.logout()}
      >
        Logout
      </Button>
    </div>
  );
};

export default NoAccess;
