import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import authClient, { getToken, logout } from 'auth';
import { hasAuthParams } from 'utils/api';

const CallbackPage = () => {
  const history = useHistory();

  const onRedirectCallback = appState => {
    history.replace(appState?.returnTo || '/');
  };

  useEffect(() => {
    (async () => {
      try {
        if (hasAuthParams()) {
          const { appState } = await authClient.handleRedirectCallback();
          await getToken();
          onRedirectCallback(appState);
        } else {
          history.push('/');
        }
      } catch (error) {
        await logout({
          result: 'error',
          msg: error.message,
        });
      }
    })();
  }, []);

  return null;
};

export default CallbackPage;
