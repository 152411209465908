import { init } from '@rematch/core';
import loadingPlugin from '@rematch/loading';
import persistPlugin from '@rematch/persist';
import immerPlugin from '@rematch/immer';
import config from 'config';
import ROOT_PERSIST from 'config/redux-persist';
import * as models from 'models';
import { initNotifications } from 'utils/notifications';
const store = init({
    models,
    plugins: [
        immerPlugin(),
        loadingPlugin(),
        persistPlugin(ROOT_PERSIST), // support for redux-persist
    ],
    redux: {
        devtoolOptions: {
            disabled: config.isProduction,
        },
    },
});
initNotifications(store);
export default store;
