import _ from 'lodash';

const useCasesConstants = {
  valueDrivers: [
    {
      name: 'Cash Conversion Cycle',
      _id: 'cashConversion',
      abbrev: 'CCC',
    },
    {
      name: 'Gross margin',
      _id: 'grossMargin',
      abbrev: 'GM',
    },
    {
      name: 'Revenue Growth',
      _id: 'revenueGrowth',
      abbrev: 'RG',
    },
    {
      name: 'Revenue per Employee',
      _id: 'employeeRevenue',
      abbrev: 'R/E',
    },
    {
      name: 'SG&A Margin',
      _id: 'salesGeneralAdmin',
      abbrev: 'SG&A',
    },
  ],

  performanceGroup: {
    weakness: 'weakness',
    average: 'average',
    strength: 'strength',
  },

  industryGroups: [
    {
      name: 'CPG',
      industries: [
        '583c29912d2b643c50b78c41', // Beverages
        '5809b92fe909e296084e9798', // Household Products
        '5809e042714420a155307477', // Leisure Products
        '5809ba63e909e296084e97a1', // Personal Products
        '583c29ea2d2b643c50b78c4b', // Textiles, Apparel & Luxury Goods
        '583c29ce2d2b643c50b78c4a', // Tobacco
      ],
    },
    {
      name: 'Retail',
      industries: [
        '583c29c32d2b643c50b78c47', // Multiline Retail
        '5809ba6fe909e296084e97a2', // Specialty Retail
        '583c298d2d2b643c50b78c40', // Food & Staples Retailing
      ],
    },
    {
      name: 'Telecoms',
      industries: [
        '5809b8cce909e296084e9792', // Diversified Telecommunication Services
        '57fe0db67d21cc216e07200f', // Wireless Telecommunication Services
      ],
    },
    {
      name: 'Travel & Transportation',
      industries: [
        '5809bb47e909e296084e97a7', // Airlines
        '5809bb82e909e296084e97a8', // Hotels, Restaurants & Leisure
        '583c29c62d2b643c50b78c48', // Road & Rail
        '583c2b722d2b643c50b78c56', // Transportation Infrastructure
        '5914232866716f001de74407', // Travel Agencies and Services
      ],
    },
    {
      name: 'Utilities',
      industries: [
        '583c299f2d2b643c50b78c43', // Electric Utilities
        '583c2a3d2d2b643c50b78c4f', // Gas Utilities
        '583c29bc2d2b643c50b78c45', // Multi-Utilities
        '583c2df42d2b643c50b78c5a', // Water Utilities
      ],
    },
    {
      name: 'Wholesale Distribution',
      industries: [
        '583c2c792d2b643c50b78c58', // Distributors
        '5809b91ce909e296084e9796', // Food Products
        '583c29b12d2b643c50b78c44', // Trading Companies & Distributors
      ],
    },
    {
      name: 'Supply chain',
      industries: [
        '5809b9e0e909e296084e979f', // Aerospace & Defense
        '5809baf3e909e296084e97a5', // Air Freight & Logistics
        '5809bb47e909e296084e97a7', // Airlines
        '5809bbd4e909e296084e97aa', // Auto Components
        '580845a62d176b000fdf423c', // Automobiles
        '583c29912d2b643c50b78c41', // Beverages
        '583c2a1d2d2b643c50b78c4e', // Building Products
        '583c299c2d2b643c50b78c42', // Chemicals
        '5809c09ce909e296084e97ad', // Commercial Services & Supplies
        '5809b99ee909e296084e979e', // Communications Equipment
        '583c29c02d2b643c50b78c46', // Construction & Engineering
        '583c2a7e2d2b643c50b78c52', // Construction Materials
        '583c2ab32d2b643c50b78c54', // Containers & Packaging
        '583c2c792d2b643c50b78c58', // Distributors
        '5809b8cce909e296084e9792', // Diversified Telecommunication Services
        '583c299f2d2b643c50b78c43', // Electric Utilities
        '583c29f62d2b643c50b78c4c', // Electrical Equipment
        '5809ba83e909e296084e97a3', // Electronic Equipment, Instruments & Components
        '583c29ca2d2b643c50b78c49', // Energy Equipment & Services
        '583c298d2d2b643c50b78c40', // Food & Staples Retailing
        '5809b91ce909e296084e9796', // Food Products
        '583c2a3d2d2b643c50b78c4f', // Gas Utilities
        '5809bab6e909e296084e97a4', // Health Care Equipment & Supplies
        '5809b945e909e296084e979a', // Health Care Providers & Services
        '5809bb82e909e296084e97a8', // Hotels, Restaurants & Leisure
        '5809bb87e909e296084e97a9', // Household Durables
        '5809b92fe909e296084e9798', // Household Products
        '583c2a972d2b643c50b78c53', // Independent Power and Renewable Electricity Producers
        '5809b972e909e296084e979b', // Industrial Conglomerates
        '5809bc1de909e296084e97ab', // Internet & Direct Marketing Retail
        '5809e042714420a155307477', // Leisure Products
        '5855c4f7e8cf39285dda9b6a', // Life Sciences Tools & Services
        '5809bb15e909e296084e97a6', // Machinery
        '583c2a542d2b643c50b78c51', // Marine
        '583c2a192d2b643c50b78c4d', // Metals & Mining
        '5808107e2d176b000fdf40fa', // Oil, Gas & Consumable Fuels
        '583c2c322d2b643c50b78c57', // Paper & Forest Products
        '5809ba63e909e296084e97a1', // Personal Products
        '5809b919e909e296084e9795', // Pharmaceuticals
        '583c2a4c2d2b643c50b78c50', // Real Estate Management & Development
        '5809b98fe909e296084e979d', // Semiconductors & Semiconductor Equipment
        '5809ba6fe909e296084e97a2', // Specialty Retail
        '5809b8bde909e296084e9791', // Technology Hardware, Storage & Peripherals
        '583c29ea2d2b643c50b78c4b', // Textiles, Apparel & Luxury Goods
        '583c29ce2d2b643c50b78c4a', // Tobacco
        '583c29b12d2b643c50b78c44', // Trading Companies & Distributors
        '583c2b722d2b643c50b78c56', // Transportation Infrastructure
        '583c2df42d2b643c50b78c5a', // Water Utilities
        '57fe0db67d21cc216e07200f', // Wireless Telecommunication Services
      ],
    },
  ],

  genericOrganization: { id: '000000000000000000000000', name: 'Generic' },
};

const valueDriversByName = {};

useCasesConstants.valueDrivers.forEach(valueDriver => {
  valueDriversByName[valueDriver.name] = valueDriver;
});

useCasesConstants.valueDriversByName = valueDriversByName;
useCasesConstants.valueDriversById = _.keyBy(
  useCasesConstants.valueDrivers,
  '_id',
);

export default useCasesConstants;
