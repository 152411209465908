import { resourcesConstants, useCasesConstants } from 'shared/constants';
import Promise from 'bluebird';
import solutionsApi from './solutions';
import useCaseDraftsApi from './use-case-drafts';
import resourcesApi from './resources';

const miscellaneous = {
  getCaseEditorData: async orgId => {
    let solutionsReq;

    if (orgId === useCasesConstants.genericOrganization.id) {
      solutionsReq = solutionsApi.getGeneric({
        populate: 'cases',
        limit: -1,
      });
    } else {
      solutionsReq = solutionsApi.getAll({
        limit: -1,
        organization: orgId,
        populate: 'cases',
      });
    }

    const [solutions, drafts, industryGroups] = await Promise.all([
      solutionsReq,
      useCaseDraftsApi.getChanges(orgId),
      resourcesApi.getAll({
        resourceType: resourcesConstants.types.industryGroup,
        'meta.organization.id': orgId,
      }),
    ]);

    return {
      solutions,
      drafts,
      industryGroups,
    };
  },
};

export default miscellaneous;
