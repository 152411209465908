import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as Common from 'models/common';
import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';

import Button from '@mui/material/Button';

const Notifier = ({ notifications, close }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const displayed = useRef({});

  const onDismissClick = key => {
    close(key);
    closeSnackbar(key);
  };

  const dismissButton = key => (
    <Button color='text' onClick={() => onDismissClick(key)}>
      Dismiss
    </Button>
  );

  useEffect(() => {
    if (!notifications.length) {
      displayed.current = {};
    }

    notifications.forEach(({ key, message, options, hideAction }) => {
      if (!displayed.current[key]) {
        displayed.current[key] = true;
        enqueueSnackbar(message, {
          key,
          ...options,
          onClose: (event, reason, key) => {
            close(key);
          },
          action: !hideAction && dismissButton,
        });
      }
    });
  }, [notifications]);

  return null;
};

Notifier.propTypes = {
  notifications: PropTypes.array.isRequired,
  close: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  notifications: Common.getNotifications(state),
});

const mapDispatchToProps = dispatch => ({
  close: dispatch.common.closeNotification,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notifier);
