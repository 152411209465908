import React from 'react';
import PropTypes from 'prop-types';
import RSSpinner from 'react-spinkit';
import theme from 'theme';
import CenteredContainer from 'components/centered-container';
const Spinner = ({ absolute, fadeIn, className, style, name, }) => {
    // const theme = useTheme<CustomTheme>();
    return (React.createElement(CenteredContainer, { absolute: absolute, className: className, style: style },
        React.createElement(RSSpinner, { name: name, fadeIn: fadeIn, color: theme.palette.primary.main })));
};
Spinner.propTypes = {
    absolute: PropTypes.bool,
    className: PropTypes.string,
    name: PropTypes.string,
    style: PropTypes.object,
    fadeIn: PropTypes.oneOf(['full', 'half', 'quarter', 'none']),
};
Spinner.defaultProps = {
    fadeIn: 'none',
    name: 'three-bounce',
};
export default Spinner;
