import _ from 'lodash';
import { handleError, parsePaginatedResponse, paginatedQuery } from 'utils/api';
import { createSelector } from 'reselect';
import healthchecksApi from 'api/healthchecks';

const initialState = {
  summary: [],
  selected: null,
  detailsById: {},
  detailsPages: 0,
  detailsCount: 0,
};

const transform = healthcheck => ({
  _id: _.get(healthcheck, '_id'),
  companyId: _.get(healthcheck, 'company._id'),
  companyName: _.get(healthcheck, 'company.name'),
  country: _.get(healthcheck, 'company.headquarters.country'),
  LTMRevenue: _.get(healthcheck, 'company.latestRevenue.valueUSD'),
  followerCount: _.get(healthcheck, 'company.followerCount'),
  updatedAt: _.get(healthcheck, 'updatedAt'),
  result: _.get(healthcheck, 'result'),
  failure: _.get(healthcheck, 'failure'),
  error: _.get(healthcheck, 'error'),
  acknowledged: _.get(healthcheck, 'acknowledged'),
});

export default {
  state: initialState,
  reducers: {
    setSummary: (state, summary) => {
      state.summary = summary;
      return state;
    },
    setSelected: (state, healthcheckType) => {
      state.selected = healthcheckType;
      return state;
    },
    setDetails: (state, details) => {
      state.detailsPages = details.pages;
      state.detailsCount = details.count;

      const detailsData = _.map(details.data, transform);
      state.detailsById = _.keyBy(detailsData, '_id');
      return state;
    },
    setCheck: (state, healthcheck) => {
      const details = state.detailsById[healthcheck._id];
      details.updatedAt = healthcheck.updatedAt;
      details.result = healthcheck.result;
      details.failure = healthcheck.failure;
      details.error = healthcheck.error;
      details.acknowledged = healthcheck.acknowledged;

      return state;
    },
  },
  effects: {
    async fetchSummary() {
      try {
        const summary = await healthchecksApi.summary();
        this.setSummary(summary);
      } catch (e) {
        handleError(e);
      }
    },
    async fetchDetail(payload, rootState) {
      try {
        const selectedHealthcheck = getSelected(rootState);

        const fields = [
          'type',
          'updatedAt',
          'result',
          'failure',
          'error',
          'company._id',
          'company.name',
          'company.latestRevenue.valueUSD',
          'company.headquarters.country',
          'company.followerCount',
          'acknowledged',
        ];
        const response = await healthchecksApi.detail(selectedHealthcheck, {
          fields: fields.join(','),
          ...paginatedQuery(payload),
        });

        const { data, pageCount, dataCount } = await parsePaginatedResponse(
          payload,
          response,
        );

        this.setDetails({
          data,
          pages: pageCount,
          count: dataCount,
        });
      } catch (err) {
        handleError(err);
      }
    },
    async runCheck(companyId, rootState) {
      try {
        const selectedHealthcheck = getSelected(rootState);

        const response = await healthchecksApi.runCheck({
          type: selectedHealthcheck,
          companyId,
        });

        this.setCheck(response);
      } catch (err) {
        handleError(err);
      }
    },
    async acknowledge(_id) {
      try {
        const response = await healthchecksApi.acknowledge({
          id: _id,
        });

        this.setCheck(response);
      } catch (err) {
        handleError(err);
      }
    },
  },
};

/** ********** Dispatchers *********** */
export const fetchSummary = d => d.healthchecks.fetchSummary;
export const fetchDetail = d => d.healthchecks.fetchDetail;
export const runCheck = d => d.healthchecks.runCheck;
export const setSelected = d => d.healthchecks.setSelected;
export const acknowledge = d => d.healthchecks.acknowledge;

/** ********** Selectors *********** */

export const getDetailsById = s => s.healthchecks.detailsById;
export const getDetails = createSelector(
  getDetailsById,
  byId => _.values(byId),
);
export const getDetailsPages = s => s.healthchecks.detailsPages;
export const getDetailsCount = s => s.healthchecks.detailsCount;
export const getSelected = s => s.healthchecks.selected;
export const getSummary = s => s.healthchecks.summary;

export const isLoadingFetchSummary = s =>
  s.loading.effects.healthchecks.fetchSummary;
export const isLoadingFetchDetail = s =>
  s.loading.effects.healthchecks.fetchDetail;
export const isLoadingRunCheck = s => s.loading.effects.healthchecks.runCheck;
export const isLoadingAcknowledged = s =>
  s.loading.effects.healthchecks.acknowledge;
