import _ from 'lodash';
import { handleError } from 'utils/api';
import { createSelector } from 'reselect';
import industriesApi from 'api/industries';

const initialState = {
  notifications: [],
  industriesById: {},
};

export default {
  state: initialState,
  reducers: {
    notify: (state, notification) => {
      state.notifications.push(notification);
      return state;
    },
    closeNotification: (state, notificationKey) => {
      state.notifications = state.notifications.filter(
        notification => notification.key !== notificationKey,
      );

      return state;
    },
    setIndustries: (state, industriesById) => {
      state.industriesById = industriesById;
      return state;
    },
  },
  effects: {
    async fetchIndustries(payload, rootState) {
      if (!getIndustryIds(rootState).length) {
        try {
          const industries = await industriesApi.getAll({
            limit: -1,
          });
          this.setIndustries(_.keyBy(industries, '_id'));
        } catch (e) {
          handleError(e);
        }
      }
    },
  },
};

/** ********** Dispatchers *********** */

export const notify = d => d.common.notify;
export const fetchIndustries = d => d.common.fetchIndustries;

/** ********** Selectors *********** */

export const getNotifications = s => s.common.notifications;
export const getIndustriesById = s => s.common.industriesById;
export const getIndustries = createSelector(
  getIndustriesById,
  byId => _.sortBy(Object.values(byId), 'name'),
);
export const getIndustryIds = createSelector(
  getIndustries,
  industries => industries.map(i => i._id),
);
export const getIndustryNames = createSelector(
  getIndustries,
  industries => industries.map(i => i.name),
);
export const getIndustriesByName = createSelector(
  getIndustries,
  industries => _.keyBy(industries, 'name'),
);
