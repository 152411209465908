import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import { Route } from 'react-router-dom';
import HelpCircleIcon from 'mdi-react/HelpCircleOutlineIcon';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(1, 0),
    color: theme.palette.secondary.main,
  },
  iconRoot: {
    minWidth: 40,
    color: theme.palette.secondary.main,
  },
}));

const helpLinks = [
  [
    'how-to-use-cases',
    '/configuration/cases',
    'https://databook.atlassian.net/wiki/spaces/CC/pages/201654325/How+to+use+-+Configuration+-+Use+Cases',
    'How to use Cases',
  ],
  [
    'how-to-intent-dashboard',
    '/configuration/intent-dashboard',
    'https://databook.atlassian.net/wiki/spaces/CC/pages/126353409/How+to+use+-+Configuration+-+Intent+Dashboard',
    'How to use Intent Dashboard',
  ],
  [
    'how-to-data-studio',
    '/configuration/intent-dashboard',
    'https://databook.atlassian.net/wiki/spaces/intentdashboard/pages/127009015/Intent+Dashboard+-+3rd+Step+-+Data+Studio+Report',
    'Creating Data Studio Report',
  ],
];

const HelpLinks = () => {
  const classes = useStyles();

  const openLink = link => {
    window.open(link, '__blank');
  };

  return (
    <>
      {helpLinks.map(([key, internalRoute, helpLink, title]) => (
        <Route key={key} path={internalRoute}>
          <>
            <ListItem
              onClick={() => openLink(helpLink)}
              button
              dense
              className={classes.container}
            >
              <ListItemIcon classes={{ root: classes.iconRoot }}>
                <HelpCircleIcon />
              </ListItemIcon>
              <ListItemText>{title}</ListItemText>
            </ListItem>
            <Divider />
          </>
        </Route>
      ))}
    </>
  );
};

export default HelpLinks;
