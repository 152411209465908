import ms from 'ms';
import api from './base';

const reports = {
  getLicenseUsageReport: options =>
    api
      .get('reports/licenseUsage', {
        searchParams: options,
        timeout: ms('2min'),
      })
      .json(),
  getUserActivityReport: options =>
    api
      .get('reports/userActivityReport', {
        searchParams: options,
        timeout: ms('2min'),
      })
      .json(),
  getGroupActivityReport: options =>
    api
      .get('reports/groupActivityReport', {
        searchParams: options,
        timeout: ms('2min'),
      })
      .json(),
  getAccountActivityReport: options =>
    api
      .get('reports/accountActivityReport', {
        searchParams: options,
        timeout: ms('2min'),
      })
      .json(),
};

export default reports;
