import api from './base';

const healthchecks = {
  runCheck: ({ companyId, type }, params) =>
    api
      .put('healthchecks', { json: { companyId, type }, searchParams: params })
      .json(),
  summary: () => api.get('healthchecks/summary').json(),
  detail: (type, params) =>
    api.get(`healthchecks/${type}/detail`, { searchParams: params }),
  acknowledge: ({ id }, params) =>
    api
      .put('healthchecks/acknowledge', { json: { id }, searchParams: params })
      .json(),
};

export default healthchecks;
