import _ from 'lodash';
import { createSelector } from 'reselect';
import constants from 'shared/constants';
import lambdaApi from 'api/lambda';
import {
  handleError,
  showSuccess,
  paginatedQuery,
  parsePaginatedResponse,
} from 'utils/api';

const { intentDashboard } = constants.lambdas;

const initialState = {
  organization: null,
  companies: [],
  keywords: [],
  companiesLambdas: [],
  companiesLambdasPages: 0,
  companiesLambdasCount: 0,
  companiesFilter: {},
  keywordsLambdas: [],
  keywordsLambdasPages: 0,
  keywordsLambdasCount: 0,
  keywordsFilter: {},
  resultsLambdas: [],
  resultsLambdasPages: 0,
  resultsLambdasCount: 0,
  resultsFilter: {},
  selectedCompanyFile: null,
  selectedKeywordFile: null,
  dialogLambdaError: null,
};

export default {
  state: initialState,
  reducers: {
    setOrganization(state, organization) {
      if (!organization) {
        return initialState;
      }

      state.organization = organization;
      return state;
    },
    addCompanies(state, companies) {
      state.companies = _.uniqBy([...companies, ...state.companies], '_id');
      return state;
    },
    removeCompany(state, id) {
      state.companies = state.companies.filter(company => company._id !== id);
      return state;
    },
    removeAllCompanies(state) {
      state.companies = [];
      return state;
    },
    addKeywords(state, keywords) {
      state.keywords.push(...keywords);
      return state;
    },
    editKeyword(state, keyword) {
      const index = state.keywords.findIndex(k => k._id === keyword._id);
      if (index !== -1) {
        state.keywords[index] = keyword;
      }
      return state;
    },
    removeKeyword(state, id) {
      state.keywords = state.keywords.filter(keyword => keyword._id !== id);
      return state;
    },
    removeAllKeywords(state) {
      state.keywords = [];
      return state;
    },
    setCompaniesLambdas(state, { lambdas, filter, pages, count }) {
      state.companiesLambdas = lambdas;
      state.companiesLambdasCount = count;
      state.companiesFilter = filter;
      state.companiesLambdasPages = pages;
      return state;
    },
    setKeywordsLambdas(state, { lambdas, filter, pages, count }) {
      state.keywordsLambdas = lambdas;
      state.keywordsLambdasCount = count;
      state.keywordsFilter = filter;
      state.keywordsLambdasPages = pages;
      return state;
    },
    setResultsLambdas(state, { lambdas, filter, pages, count }) {
      state.resultsLambdas = lambdas;
      state.resultsLambdasCount = count;
      state.resultsFilter = filter;
      state.resultsLambdasPages = pages;
      return state;
    },
    selectCompanyFile(state, fileName) {
      state.selectedCompanyFile = fileName;
      return state;
    },
    selectKeywordFile(state, fileName) {
      state.selectedKeywordFile = fileName;
      return state;
    },
    setDialogLambdaError(state, error) {
      state.dialogLambdaError = error;
    },
  },
  effects: {
    async runCompaniesJob(payload, rootState) {
      const organization = getOrganization(rootState);
      const companiesIds = getCompaniesIds(rootState);

      try {
        await lambdaApi.start(
          intentDashboard.group,
          intentDashboard.functions.companies,
          { organization: organization.id, companyIds: companiesIds },
        );
        this.removeAllCompanies();
        showSuccess('Successfully started job!');
      } catch (err) {
        handleError(err);
      }
    },
    async runKeywordsJob(payload, rootState) {
      const organization = getOrganization(rootState);
      const keywords = getKeywords(rootState);

      try {
        await lambdaApi.start(
          intentDashboard.group,
          intentDashboard.functions.entities,
          {
            organization: organization.id,
            entities: keywords.map(k => ({
              name: k.keyword,
              multiplier: k.weight,
              aliases: k.aliases,
            })),
          },
        );
        this.removeAllKeywords();
        showSuccess('Successfully started job!');
      } catch (err) {
        handleError(err);
      }
    },
    async fetchCompaniesLambdas(payload, rootState) {
      try {
        const filters = payload || getCompaniesFilter(rootState);
        const organization = getOrganization(rootState);

        const response = await lambdaApi.getAll({
          group: intentDashboard.group,
          function: intentDashboard.functions.companies,
          'data.organization': organization.id,
          ...paginatedQuery(filters),
        });

        const { data, pageCount, dataCount } = await parsePaginatedResponse(
          filters,
          response,
        );

        this.setCompaniesLambdas({
          lambdas: data,
          pages: pageCount,
          filter: filters,
          count: dataCount,
        });
      } catch (err) {
        handleError(err);
      }
    },
    async fetchKeywordsLambdas(payload, rootState) {
      try {
        const filters = payload || getKeywordsFilter(rootState);
        const organization = getOrganization(rootState);

        const response = await lambdaApi.getAll({
          group: intentDashboard.group,
          function: intentDashboard.functions.entities,
          'data.organization': organization.id,
          ...paginatedQuery(filters),
        });

        const { data, pageCount, dataCount } = await parsePaginatedResponse(
          filters,
          response,
        );

        this.setKeywordsLambdas({
          lambdas: data,
          pages: pageCount,
          filter: filters,
          count: dataCount,
        });
      } catch (err) {
        handleError(err);
      }
    },
    async fetchResultsLambdas(payload, rootState) {
      try {
        const filters = payload || getResultsFilter(rootState);
        const organization = getOrganization(rootState);

        const response = await lambdaApi.getAll({
          group: intentDashboard.group,
          function: intentDashboard.functions.results,
          'data.organization': organization.id,
          ...paginatedQuery(filters),
        });

        const { data, pageCount, dataCount } = await parsePaginatedResponse(
          filters,
          response,
        );

        this.setResultsLambdas({
          lambdas: data,
          pages: pageCount,
          filter: filters,
          count: dataCount,
        });
      } catch (err) {
        handleError(err);
      }
    },
    async stopLambda(id) {
      try {
        await lambdaApi.stop(id);
        showSuccess('Stopped task successfully');
      } catch (err) {
        handleError(err);
      }
    },
  },
};

/** ********** Dispatchers *********** */

export const setOrganization = d => d.intent.setOrganization;
export const addCompanies = d => d.intent.addCompanies;
export const removeCompany = d => d.intent.removeCompany;
export const removeAllCompanies = d => d.intent.removeAllCompanies;
export const runCompaniesJob = d => d.intent.runCompaniesJob;
export const addKeywords = d => d.intent.addKeywords;
export const editKeyword = d => d.intent.editKeyword;
export const removeKeyword = d => d.intent.removeKeyword;
export const removeAllKeywords = d => d.intent.removeAllKeywords;
export const runKeywordsJob = d => d.intent.runKeywordsJob;
export const fetchCompaniesLambdas = d => d.intent.fetchCompaniesLambdas;
export const fetchKeywordsLambdas = d => d.intent.fetchKeywordsLambdas;
export const fetchResultsLambdas = d => d.intent.fetchResultsLambdas;
export const selectCompanyFile = d => d.intent.selectCompanyFile;
export const selectKeywordFile = d => d.intent.selectKeywordFile;
export const stopLambda = d => d.intent.stopLambda;
export const setDialogLambdaError = d => d.intent.setDialogLambdaError;

/** ********** Selectors *********** */

export const getOrganization = s => s.intent.organization;
export const getCompanies = s => s.intent.companies;
export const getCompaniesIds = createSelector(
  getCompanies,
  companies => companies.map(company => company._id),
);
export const isRunningCompaniesJob = s =>
  s.loading.effects.intent.runCompaniesJob;
export const getKeywords = s => s.intent.keywords;
export const getKeywordsById = createSelector(
  getKeywords,
  list => _.keyBy(list, '_id'),
);
export const isRunningKeywordsJob = s =>
  s.loading.effects.intent.runKeywordsJob;
export const getCompaniesLambdas = s => s.intent.companiesLambdas;
export const getCompaniesLambdasPages = s => s.intent.companiesLambdasPages;
export const getCompaniesLambdasCount = s => s.intent.companiesLambdasCount;
export const getKeywordsLambdas = s => s.intent.keywordsLambdas;
export const getKeywordsLambdasPages = s => s.intent.keywordsLambdasPages;
export const getKeywordsLambdasCount = s => s.intent.keywordsLambdasCount;
export const getResultsLambdas = s => s.intent.resultsLambdas;
export const getResultsLambdasPages = s => s.intent.resultsLambdasPages;
export const getResultsLambdasCount = s => s.intent.resultsLambdasCount;
export const getSelectedCompanyFile = s => s.intent.selectedCompanyFile;
export const getSelectedKeywordFile = s => s.intent.selectedKeywordFile;
const getCompaniesFilter = s => s.intent.companiesFilter;
export const isLoadingCompaniesLambdas = s =>
  s.loading.effects.intent.fetchCompaniesLambdas;
const getKeywordsFilter = s => s.intent.keywordsFilter;
export const isLoadingKeywordsLambdas = s =>
  s.loading.effects.intent.fetchKeywordsLambdas;
const getResultsFilter = s => s.intent.resultsFilter;
export const isLoadingResultsLambdas = s =>
  s.loading.effects.intent.fetchResultsLambdas;
export const getDialogLambdaError = s => s.intent.dialogLambdaError;
