/* eslint-disable global-require,security/detect-non-literal-require */

const AUTH0 = {
  local: {
    clientId: process.env.STAGING_AUTH0_CLIENT_ID,
    domain: process.env.STAGING_AUTH0_DOMAIN,
    audience: process.env.STAGING_AUTH0_AUDIENCE,
    connection: process.env.STRAGING_AUTH0_CONNECTION,
  },
  staging: {
    clientId: process.env.STAGING_AUTH0_CLIENT_ID,
    domain: process.env.STAGING_AUTH0_DOMAIN,
    audience: process.env.STAGING_AUTH0_AUDIENCE,
    connection: process.env.STRAGING_AUTH0_CONNECTION,
  },
  production: {
    clientId: process.env.AUTH0_CLIENT_ID,
    domain: process.env.AUTH0_DOMAIN,
    audience: process.env.AUTH0_AUDIENCE,
    connection: process.env.AUTH0_CONNECTION,
  },
  sbx: {
    clientId: process.env.AUTH0_CLIENT_ID,
    domain: process.env.AUTH0_DOMAIN,
    audience: process.env.AUTH0_AUDIENCE,
    connection: process.env.STRAGING_AUTH0_CONNECTION,
  },
  dev: {
    clientId: process.env.AUTH0_CLIENT_ID,
    domain: process.env.AUTH0_DOMAIN,
    audience: process.env.AUTH0_AUDIENCE,
    connection: process.env.STRAGING_AUTH0_CONNECTION,
  },
  stg: {
    clientId: process.env.AUTH0_CLIENT_ID,
    domain: process.env.AUTH0_DOMAIN,
    audience: process.env.AUTH0_AUDIENCE,
    connection: process.env.STRAGING_AUTH0_CONNECTION,
  },
  prd: {
    clientId: process.env.AUTH0_CLIENT_ID,
    domain: process.env.AUTH0_DOMAIN,
    audience: process.env.AUTH0_AUDIENCE,
    connection: process.env.AUTH0_CONNECTION,
  },
};

const API = {
  local: 'http://localhost:8080',
  staging: 'https://api-staging.trydatabook.com/api',
  production: 'https://api.trydatabook.com/api',
  sbx: 'https://sbx.databook.io/core-api/api',
  dev: 'https://dev.databook.io/core-api/api',
  stg: 'https://stg.databook.io/core-api/api',
  prd: 'https://prd.databook.io/core-api/api',
};

const CLOUDINARY = {
  production: {
    cloudName: process.env.CLOUDINARY_CLOUD_NAME,
    apiKey: process.env.CLOUDINARY_API_KEY,
  },
  prd: {
    cloudName: process.env.CLOUDINARY_CLOUD_NAME,
    apiKey: process.env.CLOUDINARY_API_KEY,
  },
  staging: {
    cloudName: process.env.STAGING_CLOUDINARY_CLOUD_NAME,
    apiKey: process.env.STAGING_CLOUDINARY_API_KEY,
  },
  sbx: {
    cloudName: process.env.STAGING_CLOUDINARY_CLOUD_NAME,
    apiKey: process.env.STAGING_CLOUDINARY_API_KEY,
  },
  dev: {
    cloudName: process.env.STAGING_CLOUDINARY_CLOUD_NAME,
    apiKey: process.env.STAGING_CLOUDINARY_API_KEY,
  },
  local: {
    cloudName: process.env.STAGING_CLOUDINARY_CLOUD_NAME,
    apiKey: process.env.STAGING_CLOUDINARY_API_KEY,
  },
};

const devVariables = {
  env: 'development',
  isProduction: false,
  algolia: {
    appId: process.env.ALGOLIA_APP_ID,
    adminApiKey: process.env.ALGOLIA_ADMIN_API_KEY,
  },
  apiUrl: API[process.env.API_ENV || 'staging'],
  auth0: AUTH0[process.env.INFRA_ENV] || 'staging',
  cloudinary: CLOUDINARY[process.env.INFRA_ENV],
};

const prodVariables = {
  env: 'production',
  isProduction: true,
  algolia: {
    appId: process.env.ALGOLIA_APP_ID,
    adminApiKey: process.env.ALGOLIA_ADMIN_API_KEY,
  },
  amplitudeKey: process.env.AMPLITUDE_KEY,
  intercomAppId: process.env.INTERCOM_APP_ID,

  apiUrl: process.env.API_URL,
  auth0: {
    clientId: process.env.AUTH0_CLIENT_ID,
    domain: process.env.AUTH0_DOMAIN,
    audience: process.env.AUTH0_AUDIENCE,
    connection: process.env.AUTH0_CONNECTION,
  },
  cloudinary: {
    cloudName: process.env.CLOUDINARY_CLOUD_NAME,
    apiKey: process.env.CLOUDINARY_API_KEY,
  },
};

const variables =
  process.env.NODE_ENV === 'production' ? prodVariables : devVariables;

export default { ...variables };
