import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  absoluteContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 999,
  },
}));

const CenteredContainer = ({ absolute, children, className, style }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        {
          [classes.absoluteContainer]: absolute,
          [classes.container]: !absolute,
        },
        className,
      )}
      style={style}
    >
      {children}
    </div>
  );
};

CenteredContainer.propTypes = {
  absolute: PropTypes.bool,
  children: PropTypes.any,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default CenteredContainer;
