import { Auth0Client } from '@auth0/auth0-spa-js';
import config from 'config';
import { getPersistor } from '@rematch/persist';

const auth = new Auth0Client({
  domain: config.auth0.domain,
  clientId: config.auth0.clientId,
  authorizationParams: {
    redirect_uri: `${window.location.origin}/login`,
    audience: config.auth0.audience,
    cacheLocation: 'localstorage',
    useRefreshTokens: true,
  },
});

let isLoggingIn = false; // helps to avoid calling redirection multiple times, as it creates extra cookies
let isLoggingOut = false;

export async function getToken() {
  let accessToken = '';

  try {
    accessToken = await auth.getTokenSilently();
  } catch (err) {
    switch (err.error) {
      case 'consent_required':
        await login({ prompt: 'consent' });
        break;
      case 'mfa_required':
      case 'interaction_required':
      case 'login_required':
        await login();
        break;
      default:
        if (!isLoggingIn) {
          await logout({
            result: 'error',
            msg: err.message,
          });
        }
    }
  }

  return accessToken;
}

export async function login(options) {
  if (!isLoggingIn) {
    isLoggingIn = true;
    await getPersistor().purge();

    const appState = { logoutParams: { returnTo: window.location.pathname } };
    await auth.loginWithRedirect({ appState, ...options });
  }
}

export async function logout(options) {
  if (!isLoggingOut) {
    isLoggingOut = true;

    await getPersistor().purge();

    auth.logout({
      // result: 'success',
      // msg: 'You have successfully logged out',
      logoutParams: {
        returnTo: `${window.location.origin}/login`,
        ...options,
      },
    });
  }
}

export default auth;
