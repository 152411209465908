import localForage from 'localforage';
import config from 'config';

const ROOT_PERSIST = {
  key: 'root',
  version: 0,
  storage: localForage,
  whitelist: [],
  debug: !config.isProduction,
};

export default ROOT_PERSIST;
