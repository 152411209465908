import _ from 'lodash';
import Promise from 'bluebird';
import { handleError, showSuccess } from 'utils/api';
import { createSelector } from 'reselect';
import { original } from 'immer';
import constants from 'shared/constants';
import { useCaseDraftUtils as utils, useCaseDraftUtils } from 'shared/utils';
import miscellaneousApi from 'api/miscellaneous';
import useCaseDraftsApi from 'api/use-case-drafts';
import casesApi from 'api/cases';
import * as Common from './common';
const { actions, dialogModes } = constants;

const initialState = {
  organization: null,
  industryGroupsById: {},
  remoteUpdatesById: {},
  localUpdatesById: {},
  solutionsById: {},
  useCasesById: {},
  dialogOptions: null,
  solutionFilter: null,
  inQAMode: false,
  submitErrors: null,
};

export default {
  state: initialState,
  reducers: {
    setOrganization(state, organization) {
      state.organization = organization;
      state.solutionsById = {};
      state.useCasesById = {};
      state.remoteUpdatesById = {};
      state.localUpdatesById = {};
      state.industryGroupsById = {};

      if (!organization) {
        state.submitErrors = null;
        state.inQAMode = false;
        state.solutionFilter = null;
      }

      return state;
    },

    setEditorData(state, { drafts, solutions, industryGroups }) {
      const transformedUseCases = solutions
        .flatMap(s => s.cases)
        .map(utils.transformUseCaseFromDatabook);

      state.solutionsById = _.keyBy(solutions, 'id');
      state.remoteUpdatesById = _.keyBy(drafts, 'id');
      state.useCasesById = _.keyBy(transformedUseCases, 'id');
      state.industryGroupsById = _.keyBy(industryGroups, '_id');

      return state;
    },
    setDialogOptions(state, options) {
      state.dialogOptions = options;
      return state;
    },
    revertChanges(state, id) {
      // on first click - delete local changes, on second click, remove remote changes
      if (state.localUpdatesById[id]) {
        delete state.localUpdatesById[id];
      } else if (state.remoteUpdatesById[id]) {
        state.localUpdatesById[id] = {
          id,
          action: actions.deleteDraft,
        };
      }

      return state;
    },
    deleteUseCase(state, id) {
      const remoteUpdate = state.remoteUpdatesById[id];

      if (
        _.get(state.localUpdatesById[id], 'action') === actions.created ||
        (remoteUpdate && remoteUpdate.action === actions.deleted)
      ) {
        delete state.localUpdatesById[id];
      } else {
        state.localUpdatesById[id] = { action: actions.deleted, id };
      }

      return state;
    },
    importFromFile(state, changes) {
      changes.forEach(change => {
        const { id } = change;
        const previousEdits = state.localUpdatesById[id] || {};
        const currentLocalEdits = { ...previousEdits, ...change };

        state.localUpdatesById[id] = currentLocalEdits;
        const remoteUpdatesState = state.remoteUpdatesById[id];
        const useCaseState = state.useCasesById[id];
        if (remoteUpdatesState && useCaseState) {
          const remoteUpdates = original(state.remoteUpdatesById[id]);
          const useCase = original(state.useCasesById[id]);

          if (
            utils.canDeleteLocalEdits(currentLocalEdits, remoteUpdates, useCase)
          ) {
            delete state.localUpdatesById[id];
          }
        }
      });

      return state;
    },
    applyDialogChanges(state, editions) {
      const { id, mode } = state.dialogOptions;

      const previousEdits = state.localUpdatesById[id] || {};
      const currentLocalEdits = { ...previousEdits, ...editions };

      state.localUpdatesById[id] = currentLocalEdits;
      state.localUpdatesById[id].action =
        mode === dialogModes.create ||
        currentLocalEdits.action === actions.created
          ? actions.created
          : actions.edited;

      state.dialogOptions = null;

      const remoteUpdatesOriginal = state.remoteUpdatesById[id];
      const useCaseOriginal = state.useCasesById[id];

      if (remoteUpdatesOriginal && useCaseOriginal) {
        const remoteUpdates = original(state.remoteUpdatesById[id]);
        const useCase = original(state.useCasesById[id]);
        if (
          utils.canDeleteLocalEdits(currentLocalEdits, remoteUpdates, useCase)
        ) {
          delete state.localUpdatesById[id];
        }
      }

      return state;
    },
    setCheckboxValues(
      state,
      { useCaseId, fieldKey, fieldIds, newValue, prevFieldValues },
    ) {
      let edits = state.localUpdatesById[useCaseId];

      if (!edits) {
        edits = { id: useCaseId };
        state.localUpdatesById[useCaseId] = edits;
      }

      edits.action =
        edits.action === actions.created ? actions.created : actions.edited;

      if (!edits[fieldKey]) {
        edits[fieldKey] = _.cloneDeep(prevFieldValues);
      }

      if (newValue === false) {
        fieldIds.forEach(fieldId => {
          delete edits[fieldKey][fieldId];
        });
      } else if (newValue === true) {
        fieldIds.forEach(fieldId => {
          edits[fieldKey][fieldId] = true;
        });
      }
      const stateRemoteUpdates = state.remoteUpdatesById[useCaseId];
      const stateUseCases = state.useCasesById[useCaseId];
      if (stateRemoteUpdates && stateUseCases) {
        const remoteUpdates = original(state.remoteUpdatesById[useCaseId]);
        const useCase = original(state.useCasesById[useCaseId]);

        if (utils.canDeleteLocalEdits(edits, remoteUpdates, useCase)) {
          delete state.localUpdatesById[useCaseId];
        }
      }

      return state;
    },
    moveLocalChangesToRemote(state) {
      Object.values(state.localUpdatesById).forEach(local => {
        const remote = state.remoteUpdatesById[local.id];

        if (utils.canSkipDraft(remote, local)) {
          delete state.remoteUpdatesById[local.id];
        } else {
          state.remoteUpdatesById[local.id] = utils.mergeRemoteChangesWithLocal(
            remote,
            local,
          );
        }
      });

      state.localUpdatesById = {};
      return state;
    },
    filterBySolution(state, solution) {
      state.solutionFilter = solution;
      if (!solution) {
        state.inQAMode = false;
      }
      return state;
    },
    toggleQAMode(state) {
      state.inQAMode = !state.inQAMode;
      return state;
    },
    resetLocalChanges(state) {
      state.localUpdatesById = {};
      return state;
    },
    setSubmitErrors(state, errors) {
      state.submitErrors = errors;
      return state;
    },
  },
  effects: dispatch => ({
    async setOrganization(organization) {
      if (!organization) return;

      const orgId = organization.id;

      try {
        const [data] = await Promise.all([
          miscellaneousApi.getCaseEditorData(orgId),
          Common.fetchIndustries(dispatch)(),
        ]);
        this.setEditorData(data);
      } catch (e) {
        handleError(e);
      }
    },
    async saveDraft(payload, rootState) {
      const byId = getLocalUpdatesById(rootState);
      const orgId = getOrganization(rootState).id;
      const localChanges = Object.values(byId);

      try {
        await useCaseDraftsApi.saveDraft(orgId, localChanges);
        this.moveLocalChangesToRemote();
        showSuccess('Draft saved');
      } catch (e) {
        handleError(e);
      }
    },
    async submitChanges(payload, rootState) {
      const org = getOrganization(rootState);

      try {
        const draft = await useCaseDraftsApi.getChanges(org.id);
        const chunks = _.chunk(draft, 150);

        await Promise.all(
          chunks.map(async chunk => {
            const added = chunk.filter(item => item.action === 'created');
            const deleted = chunk.filter(item => item.action === 'deleted');
            const edited = chunk.filter(item => item.action === 'edited');

            const bulkData = {
              add: added.map(item =>
                useCaseDraftUtils.transformDraftToDatabookUseCase(item, org.id),
              ),
              edit: edited.map(item => ({
                id: item.id,
                ...useCaseDraftUtils.collectEditsFromDraftToDatabookUseCase(
                  item,
                ),
              })),
              delete: deleted.map(item => item.id),
            };
            await casesApi.submitBulk(bulkData);
          }),
        );

        await useCaseDraftsApi.deleteDraft(org.id);

        this.setOrganization(org);
      } catch (e) {
        handleError(e);
        await useCaseDraftsApi.deleteDraft(org.id);
        this.setOrganization(org);
      }
    },
  }),
};

/** ********** Dispatchers *********** */

export const setOrganization = d => d.useCase.setOrganization;
export const setDialogOptions = d => d.useCase.setDialogOptions;
export const deleteUseCase = d => d.useCase.deleteUseCase;
export const revertChanges = d => d.useCase.revertChanges;
export const applyDialogChanges = d => d.useCase.applyDialogChanges;
export const saveDraft = d => d.useCase.saveDraft;
export const submitChanges = d => d.useCase.submitChanges;
export const filterBySolution = d => d.useCase.filterBySolution;
export const toggleQAMode = d => d.useCase.toggleQAMode;
export const resetLocalChanges = d => d.useCase.resetLocalChanges;
export const setSubmitErrors = d => d.useCase.setSubmitErrors;
export const importFromFile = d => d.useCase.importFromFile;
export const setCheckboxValues = d => (
  fieldKey,
  useCaseId,
  fieldIds,
  newValue,
  prevFieldValues,
) =>
  d.useCase.setCheckboxValues({
    fieldKey,
    useCaseId,
    fieldIds,
    newValue,
    prevFieldValues,
  });

/** ********** Selectors *********** */

export const getOrganization = s => s.useCase.organization;
export const isLoadingEditorData = s =>
  s.loading.effects.useCase.setOrganization;
export const getRemoteUpdatesById = s => s.useCase.remoteUpdatesById;
const getRemoteCreatedUpdates = createSelector(
  getRemoteUpdatesById,
  byId =>
    _.sortBy(
      Object.values(byId).filter(update => update.action === actions.created),
      u => -u.createdAt,
    ),
);
export const getLocalUpdatesById = s => s.useCase.localUpdatesById;
const getLocalCreatedUpdates = createSelector(
  getLocalUpdatesById,
  byId =>
    _.sortBy(
      Object.values(byId).filter(update => update.action === actions.created),
      u => -u.createdAt,
    ),
);
export const getUseCasesById = s => s.useCase.useCasesById;
export const getUseCases = createSelector(
  getUseCasesById,
  byId => _.sortBy(Object.values(byId), u => -u.createdAt),
);
export const getIndustryGroupsById = s => s.useCase.industryGroupsById;
export const getIndustryGroups = createSelector(
  getIndustryGroupsById,
  byId => _.sortBy(Object.values(byId), 'name'),
);
export const getAllMergedUseCases = createSelector(
  getLocalCreatedUpdates,
  getLocalUpdatesById,
  getRemoteCreatedUpdates,
  getRemoteUpdatesById,
  getUseCases,
  (newLocal, allLocalById, newRemote, allRemoteById, useCases) => {
    const newRemoteMergedWithLocal = newRemote.map(item =>
      utils.mergeRemoteChangesWithLocal(item, allLocalById[item.id]),
    );
    const allMergedWithLocalAndRemote = useCases.map(item =>
      utils.mergeUseCaseWithChanges(
        item,
        allRemoteById[item.id],
        allLocalById[item.id],
      ),
    );

    return [
      ...newLocal,
      ...newRemoteMergedWithLocal,
      ...allMergedWithLocalAndRemote,
    ];
  },
);
export const getAllMergedUseCasesById = createSelector(
  getAllMergedUseCases,
  list => _.keyBy(list, 'id'),
);
export const getDialogOptions = s => s.useCase.dialogOptions;
export const getSolutionsById = s => s.useCase.solutionsById;
export const getSolutions = createSelector(
  getSolutionsById,
  byId => _.sortBy(Object.values(byId), 'name'),
);
export const getSolutionsByName = createSelector(
  getSolutions,
  solutions => _.keyBy(solutions, 'name'),
);
export const getSolutionFilter = s => s.useCase.solutionFilter;
export const isInQAMode = s => s.useCase.inQAMode;
export const getSubmitErrors = s => s.useCase.submitErrors;
export const isSubmittingChanges = s => s.loading.effects.useCase.submitChanges;
export const isSavingDraft = s => s.loading.effects.useCase.saveDraft;
export const containsAnyChanges = createSelector(
  getRemoteUpdatesById,
  getLocalUpdatesById,
  (remote, local) =>
    Object.keys(remote).length > 0 || Object.keys(local).length > 0,
);
