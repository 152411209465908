import ms from 'ms';
import api from './base';

const solutions = {
  getAll: options =>
    api
      .get('solutions/all', { searchParams: options, timeout: ms('2min') })
      .json(),
  getGeneric: options =>
    api
      .get('solutions/generic', { searchParams: options, timeout: ms('2min') })
      .json(),
  create: solution => api.post('solutions', { json: solution }).json(),
  update: (id, update) => api.put(`solutions/${id}`, { json: update }).json(),
};

export default solutions;
