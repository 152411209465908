import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// Load the favicon
import '!file-loader?name=[name].[ext]!./images/favicon.ico';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { getPersistor } from '@rematch/persist';
import Spinner from 'components/progress/spinner';
import store from './store';
import ThemedApp from './themed-app';
ReactDOM.render(React.createElement(Provider, { store: store },
    React.createElement(PersistGate, { loading: React.createElement(Spinner, { fadeIn: 'half' }), persistor: getPersistor() },
        React.createElement(BrowserRouter, null,
            React.createElement(ThemedApp, null)))), document.getElementById('app'));
