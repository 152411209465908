import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  item: {
    borderRadius: '0 66px 66px 0',
  },
  active: {
    backgroundColor: theme.palette.grayHover,
    '&:hover': {
      backgroundColor: theme.palette.grayHover,
    },
  },
  iconRoot: {
    minWidth: 40,
    '& svg': {
      width: 24,
      height: 24,
      '& path': {
        fill: theme.color.iconColorGray,
      },
    },
  },
}));

const NavLink = ({ icon, primary, to, nesting, newTab, highlight }) => {
  const classes = useStyles();
  const location = useLocation();
  const active =
    location.pathname === to || (highlight && location.pathname.includes(to));

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink
          to={to}
          {...itemProps}
          innerRef={ref}
          target={newTab ? '_blank' : ''}
        />
      )),
    [to],
  );

  return (
    <li>
      <ListItem
        button
        component={renderLink}
        className={clsx(classes.item, { [classes.active]: active })}
      >
        {icon ? (
          <ListItemIcon classes={{ root: classes.iconRoot }}>
            {icon}
          </ListItemIcon>
        ) : null}
        <ListItemText
          primary={primary}
          style={{ paddingLeft: nesting ? 40 + (nesting - 1) * 15 : 0 }}
        />
      </ListItem>
    </li>
  );
};

NavLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  nesting: PropTypes.number,
  newTab: PropTypes.bool,
  highlight: PropTypes.bool,
};

NavLink.defaultProp = {
  nesting: 0,
};

export default NavLink;
