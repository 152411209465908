/**
 * Entitlement Format
 * ┌────────────────────────────────────────────────────────────────────────────────┐
 * │<EntitlementScope>:<?explicit_id>:<ResourceType>:<?explicit_id>:<?ResourceGrant>│
 * └▲──────────────────▲──────────────▲──────────────▲─────────────────────────────▲┘
 *  │                  │              │              │                             │
 *  │                  <uuidv4>       │              <uuidv4>                      │
 *  <system|organization|group|user>  <text>             <create,read,update,delete>
 */
export class EntitlementString extends String {
  constructor(value) {
    super(value?.toLowerCase());
    this.validate();
  }

  static equals(lhs, rhs) {
    return (
      lhs.getScopeType() === rhs.getScopeType() &&
      lhs.getScopeId() === rhs.getScopeId() &&
      (lhs.getResourceType() === rhs.getResourceType() ||
        lhs.getResourceType() === '*') &&
      lhs.getResourceIds().toString() === rhs.getResourceIds().toString() &&
      lhs.getResourceGrants().toString() === rhs.getResourceGrants().toString()
    );
  }

  getScopeType() {
    return this.split(':')[0];
  }

  getScopeId() {
    const scope = this.split(':')[1];

    return scope.length === 0 ? '*' : scope;
  }

  getResourceType() {
    return this.split(':')[2];
  }

  getResourceIds() {
    const resource = this.split(':')[3];

    return resource.length === 0
      ? ['*']
      : resource.split(resource.includes(',') ? ',' : undefined);
  }

  getResourceGrants() {
    const grants = this.split(':')[4];

    if (grants.length === 0) return ['*'];
    const grantSplit = grants.split(grants.includes(',') ? ',' : undefined);

    if (
      grantSplit.includes('create') &&
      grantSplit.includes('read') &&
      grantSplit.includes('update') &&
      grantSplit.includes('delete')
    )
      return ['*'];

    return grantSplit.find(x => x === '*') ? ['*'] : grantSplit;
  }

  equals(other) {
    return EntitlementString.equals(this, other);
  }

  validate() {
    // TODO: this validation function can be A LOT more efficient
    const split = this.split(':');

    if (split.length !== 5) {
      throw new Error(
        `${this} malformed: expected 4 separators, saw ${
          split.length === 0 ? 0 : split.length - 1
        } instead.`,
      );
    }

    if (!split[0]) {
      throw new Error(
        `${this} malformed, expected EntitlementScope, saw ${
          split[0]
        } instead.`,
      );
    }

    if (this.getScopeType() !== 'system') {
      if (split[1].length === 0) {
        throw new Error(
          `${this} malformed, expected ScopeId to be populated for ${split[0]}`,
        );
      }

      if (split[1].includes(',')) {
        throw new Error(
          `${this} malformed, ScopeId can not be a list: ${split[1]}`,
        );
      }
    } else if (split[1].length !== 0) {
      throw new Error(
        `${this} malformed, expected ScopeId to be empty for ${split[0]}`,
      );
    }

    if (split[2].length === 0) {
      throw new Error(
        `${this} malformed, expected ResourceType to be populated.`,
      );
    }

    if (split[4].includes(',')) {
      split[4].split(',').forEach(grant => {
        if (
          !(
            grant === 'create' ||
            grant === 'read' ||
            grant === 'update' ||
            grant === 'delete' ||
            grant === '*'
          )
        ) {
          throw new Error(
            `${this} malformed, expected ResourceGrant, saw ${grant} instead.`,
          );
        }
      });
    } else if (
      !(
        split[4] === 'create' ||
        split[4] === 'read' ||
        split[4] === 'update' ||
        split[4] === 'delete' ||
        split[4] === '*'
      ) &&
      split[4].length !== 0
    ) {
      throw new Error(
        `${this} malformed, expected ResourceGrant, saw ${split[4]} instead.`,
      );
    }
  }
}
