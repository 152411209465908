/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import HomePage from 'containers/home-page';
import ControlPanel from 'containers/control-panel';
import UseCases from 'containers/use-cases';
import IntentDashboard from 'containers/intent-dashboard';
import NotFoundPage from 'containers/not-found-page';
import Interview from 'containers/interview';
import Healthchecks from 'containers/healthchecks';
import Navigation from 'containers/navigation';
import Organizations from 'containers/organizations';
import Courses from 'containers/courses';
import BuyingGroups from 'containers/buying-groups';
import SSO from 'containers/sso';
import Posts from 'containers/posts';
import Membership from 'containers/membership';
import Reports from 'containers/reports';
import CallbackPage from 'auth/callback-page';
import PrivateRoute from 'auth/private-route';
import * as User from 'models/user';
import NoAccess from 'components/no-access';
import constants from 'shared/constants';

const hideLoader = () => {
  const loader = window.document.querySelector('#databook-splash-screen');

  if (loader) {
    loader.classList.replace(
      'databook-intro-loader',
      'databook-intro-loader-hidden',
    );
  }
};

const App = ({ user }) => {
  if (user) {
    hideLoader();
  }

  if (user && user.blocked) {
    return <NoAccess />;
  }

  return (
    <Navigation>
      <Switch>
        <Route exact path='/login' component={CallbackPage} />
        <PrivateRoute
          exact
          path='/'
          component={HomePage}
          entitlement={constants.entitlements.home}
        />
        <PrivateRoute
          path='/organizations'
          component={Organizations}
          entitlement={constants.entitlements.organizations.default}
        />
        <PrivateRoute
          path='/configuration/cases'
          component={UseCases}
          entitlement={constants.entitlements.configuration.cases.default}
        />
        <PrivateRoute
          path='/configuration/courses'
          component={Courses}
          entitlement={constants.entitlements.configuration.courses}
        />
        <PrivateRoute
          path='/configuration/buying-groups'
          component={BuyingGroups}
          entitlement={constants.entitlements.configuration.buying_groups}
        />
        <PrivateRoute
          path='/configuration/sso'
          component={SSO}
          entitlement={constants.entitlements.configuration.sso}
        />
        <PrivateRoute
          path='/configuration/intent-dashboard'
          component={IntentDashboard}
          entitlement={constants.entitlements.configuration.intent_dashboard}
        />
        <PrivateRoute
          path='/configuration/queue'
          component={Posts}
          entitlement={constants.entitlements.configuration.posts}
        />
        <PrivateRoute
          path='/interview'
          component={Interview}
          entitlement={constants.entitlements.interview}
        />
        <PrivateRoute
          path='/healthchecks'
          component={Healthchecks}
          entitlement={constants.entitlements.health_checks}
        />
        <PrivateRoute
          path='/control-panel'
          component={ControlPanel}
          entitlement={constants.entitlements.control_panel}
        />
        <PrivateRoute
          path='/reports'
          component={Reports}
          entitlement={constants.entitlements.reports}
        />
        <PrivateRoute
          path='/membership'
          component={Membership}
          entitlement={constants.entitlements.membership}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </Navigation>
  );
};

App.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = state => ({
  user: User.getUser(state),
});

export default connect(mapStateToProps)(App);
