import React from 'react';
import ConfigIcon from 'mdi-react/TuneIcon';
import InterviewIcon from 'mdi-react/CodeBracesIcon';
import HealthchecksIcon from 'mdi-react/AmbulanceIcon';
import ControllerIcon from 'mdi-react/ControllerClassicIcon';
import OrganizationIcon from 'mdi-react/DomainIcon';
import ReportIcon from 'mdi-react/ChartBarIcon';
import constants from 'shared/constants/index';
import UserIcon from 'assets/icons2/person.fill.svg';

const routes = [
  {
    label: 'Interview',
    icon: <InterviewIcon />,
    route: 'interview',
    requiredEntitlement: constants.entitlements.interview,
    highlight: true,
  },
  {
    label: 'Organizations',
    icon: <OrganizationIcon />,
    route: 'organizations',
    requiredEntitlement: constants.entitlements.organizations.default,
    highlight: true,
  },
  {
    label: 'Configuration',
    icon: <ConfigIcon />,
    route: 'configuration',
    requiredEntitlement: constants.entitlements.configuration.default,
    submenu: [
      {
        label: 'Cases',
        route: 'cases',
        requiredEntitlement: constants.entitlements.configuration.cases.default,
        submenu: [
          {
            label: 'Resource Library',
            route: 'resource-library',
            highlight: true,
            requiredEntitlement:
              constants.entitlements.configuration.cases.resource_library,
          },
          {
            label: 'Case Editor',
            route: 'editor',
            highlight: true,
            requiredEntitlement:
              constants.entitlements.configuration.cases.editor,
          },
        ],
      },
      {
        label: 'Intent Dashboard',
        route: 'intent-dashboard',
        highlight: true,
        requiredEntitlement:
          constants.entitlements.configuration.intent_dashboard,
      },
      {
        label: 'Courses',
        route: 'courses',
        highlight: true,
        requiredEntitlement: constants.entitlements.configuration.courses,
      },
      {
        label: 'Buying Groups',
        route: 'buying-groups',
        highlight: true,
        requiredEntitlement: constants.entitlements.configuration.buying_groups,
      },
      {
        label: 'SSO',
        route: 'sso',
        highlight: true,
        requiredEntitlement: constants.entitlements.configuration.sso,
      },
      {
        label: 'Posts',
        route: 'queue',
        highlight: true,
        requiredEntitlement: constants.entitlements.configuration.posts,
      },
    ],
  },
  {
    label: 'Reports',
    icon: <ReportIcon />,
    route: 'reports',
    requiredEntitlement: constants.entitlements.reports,
    highlight: true,
  },
  {
    label: 'Healthchecks',
    icon: <HealthchecksIcon />,
    route: 'healthchecks',
    requiredEntitlement: constants.entitlements.health_checks,
    highlight: true,
  },
  {
    label: 'Control Panel',
    icon: <ControllerIcon />,
    route: 'control-panel',
    requiredEntitlement: constants.entitlements.control_panel,
    highlight: true,
  },
  {
    label: 'Membership',
    icon: <UserIcon />,
    route: 'membership',
    highlight: true,
    requiredEntitlement: constants.entitlements.membership,
  },
];

const config = [];

const buildConfig = (items, collection, path = '') => {
  items.forEach(item => {
    const { submenu, route } = item;
    const fullPath = `${path}/${route}`;

    if (submenu) {
      const subItems = [];

      buildConfig(submenu, subItems, fullPath);
      collection.push({ ...item, fullPath, submenu: subItems });
    } else {
      collection.push({ ...item, fullPath });
    }
  });
};

buildConfig(routes, config);

export default config;
