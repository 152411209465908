const initialState = {
  user: null,
};

export default {
  state: initialState,
  reducers: {
    setUser: (state, user) => {
      state.user = user;
      state.authError = null;
      return state;
    },
  },
};

/** ********** Dispatchers *********** */

export const setUser = d => d.user.setUser;

/** ********** Selectors *********** */

export const getUser = s => s.user.user;
export const getUserOrganization = s => s.user.user.group.organization;
