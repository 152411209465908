import api from './base';

const resources = {
  create: resource => api.post('resources', { json: resource }).json(),
  get: id => api.get(`resources/${id}`).json(),
  update: (id, update) => api.put(`resources/${id}`, { json: update }).json(),
  delete: id => api.delete(`resources/${id}`).json(),
  getAll: options => api.get(`resources/all`, { searchParams: options }).json(),
  deleteMany: ids => api.delete(`resources`, { json: ids }).json(),
};

export default resources;
