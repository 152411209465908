import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import './global.scss';
import { createTheme } from '@mui/material/styles';
// import { Typography as TypographyType } from '@material-ui/core/styles/createTypography';
import { typography, color, elevation, borderWidth, borderRadius, } from '@databook/design-tokens';
export default createTheme({
    typography: Object.assign(Object.assign({ fontFamily: '"Helvetica", "Roboto", "Arial", sans-serif' }, typography), { h1Bold: {
            color: 'red',
            fontSize: 8,
        } }),
    color,
    elevation,
    borderWidth,
    borderRadius,
    palette: {
        mode: 'light',
        primary: {
            main: '#F9423A',
        },
        secondary: {
            main: '#007286',
        },
        error: {
            main: '#d32f2f',
        },
        colorPrimary: {
            main: color.colorPrimary,
        },
        text: {
            main: '#25282A',
            primary: '#25282A',
            secondary: '#6B7378',
        },
        grey: {
            main: '#e0e0e0',
        },
        overlay: {
            dark50: 'rgba(0, 0, 0, 0.5)',
        },
        iconDark: '#3F4447',
        iconLight: '#828A8F',
        new: '#F5FDEF',
        edited: '#E7F3FF',
        deleted: '#F4DEDE',
        borderColor: '#979797',
        blue: '#0072ce',
        grayHover: '#e1e1e1',
        seaGreen: 'rgba(91, 127, 37, 0.5)',
        yellow: 'rgba(235, 185, 63, 0.5)',
        red: 'rgba(169, 73, 29, 0.5)',
        gray: {
            300: '#E6E9EB',
            400: '#C9CED1',
        },
        pumpkin: {
            50: '#FFF7E9',
            600: '#CF9023',
        },
        green: {
            50: '#E7F6EF',
            600: '#118651',
        },
        teal: {
            700: '#007286',
        },
    },
    styles: {
        tableBorder: '1px solid rgba(224, 224, 224, 1)',
    },
});
