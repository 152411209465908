import _ from 'lodash';
import { parseISO } from 'date-fns';
import constants from '../constants';

const { actions } = constants;

export const transformUseCaseFromDatabook = useCase => ({
  ...useCase,
  linkUrl: useCase.link?.url,
  linkName: useCase.link?.name,
  linkDescription: useCase.link?.description,
  keywords: useCase.tags,
  industries: useCase.industries.reduce((all, id) => {
    all[id] = true;
    return all;
  }, {}),
  valueDrivers: useCase.valueDrivers.reduce((all, id) => {
    all[id] = true;
    return all;
  }, {}),
  roles: useCase.roles,
  createdAt: parseISO(useCase.createdAt).getTime(),
});

export const transformDraftToDatabookUseCase = draft => ({
  industries: [],
  valueDrivers: [],
  roles: [],
  customerTags: [],
  classifiers: [],
  ...collectEditsFromDraftToDatabookUseCase(draft),
});

export const collectEditsFromDraftToDatabookUseCase = draft => {
  const edits = _.pick(draft, [
    'type',
    'title',
    'subtitle',
    'customerPriority',
    'ignoreSubscores',
    'text',
    'image',
    'solutionId',
  ]);

  if (draft.keywords) {
    edits.tags = draft.keywords;
  }

  if (draft.industries) {
    edits.industries = Object.keys(draft.industries);
  }

  if (draft.valueDrivers) {
    edits.valueDrivers = Object.keys(draft.valueDrivers);
  }

  if (draft.roles) {
    edits.roles = draft.roles;
  }

  if (draft.classifiers) {
    edits.classifiers = draft.classifiers;
  }

  if (draft.customerTags) {
    edits.customerTags = draft.customerTags;
  }

  // if one field there was changed, all are added to draft
  // otherwise, api would just overwrite other fields with empty values
  if (draft.linkUrl) {
    edits.link = {
      url: draft.linkUrl,
      name: draft.linkName,
      description: draft.linkDescription,
    };
  }

  return edits;
};

export const mergeRemoteChangesWithLocal = (remote, local) => {
  if (!local) return remote;
  if (!remote) return local;

  let merged = { ...remote, ...local };
  if (local.action === actions.deleted) {
    merged.action = actions.deleted;
  } else if (local.action === actions.deleteDraft) {
    merged = { action: actions.deleteDraft };
  } else if (remote.action === actions.created) {
    merged.action = actions.created;
  } else {
    merged.action = actions.edited;
  }

  return merged;
};

export const mergeUseCaseWithChanges = (
  useCase,
  remoteChanges,
  localChanges,
) => {
  if (!remoteChanges && !localChanges) return useCase;

  const mergedChanges = mergeRemoteChangesWithLocal(
    remoteChanges || {},
    localChanges,
  );
  return { ...useCase, ...mergedChanges };
};

export const canDeleteLocalEdits = (localEdits, remoteUpdates, useCase) => {
  const keysToCompare = Object.keys(localEdits).filter(
    k => !['action', 'createdAt'].includes(k),
  );
  const localEditsToCompare = _.pick(localEdits, keysToCompare);

  if (
    remoteUpdates &&
    _.isEqual(_.pick(remoteUpdates, keysToCompare), localEditsToCompare)
  ) {
    // local changes are exactly the same as current remote changes
    return true;
  }

  if (
    !remoteUpdates &&
    useCase &&
    _.isEqual(_.pick(useCase, keysToCompare), localEditsToCompare)
  ) {
    // there are no remote updates and current changes are the same as the actual use case
    return true;
  }

  return false;
};

export const canSkipDraft = (remoteChange, localChange) =>
  (remoteChange &&
    localChange &&
    // skip drafts that were previously created and now are deleted
    (remoteChange.action === actions.created &&
      localChange.action === actions.deleted)) ||
  // skip drafts that were mark to be deleted
  localChange.action === actions.deleteDraft;
