import reportApi from 'api/reports';
import { handleError } from 'utils/api';
import { sub, format, startOfDay, endOfDay } from 'date-fns';

const currentDate = new Date();
const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';

const initialState = {
  organization: null,
  licenseUsageReports: [],
  licenseUsageReportsCount: 0,
  licenseUsageReportsFilter: {},
  licenseUsageReportsPages: 0,
  userActivityReports: [],
  userActivityReportsCount: 0,
  userActivityReportsFilter: {},
  userActivityReportsPages: 0,
  groupActivityReports: [],
  groupActivityReportsCount: 0,
  groupActivityReportsFilter: {},
  groupActivityReportsPages: 0,
  accountActivityReports: [],
  accountActivityReportsCount: 0,
  accountActivityReportsFilter: {},
  accountActivityReportsPages: 0,
  startDate: format(
    sub(startOfDay(currentDate), { months: 3 }),
    DATE_TIME_FORMAT,
  ),
  endDate: format(endOfDay(currentDate), DATE_TIME_FORMAT),
  excludeDatabookUsers: true,
};

export default {
  state: initialState,
  reducers: {
    setOrganization(state, organization) {
      if (!organization) {
        return initialState;
      }

      state.organization = organization;
      return state;
    },
    setStartDate(state, startDate) {
      if (!startDate) {
        return initialState;
      }

      state.startDate = format(startOfDay(startDate), DATE_TIME_FORMAT);
      return state;
    },
    setEndDate(state, endDate) {
      if (!endDate) {
        return initialState;
      }

      state.endDate = format(endOfDay(endDate), DATE_TIME_FORMAT);
      return state;
    },
    setExcludeDatabookUsers(state, excludeDatabookUsers) {
      if (excludeDatabookUsers === null) {
        return initialState;
      }
      state.excludeDatabookUsers = excludeDatabookUsers;
      return state;
    },
    setLicenseUsageReports(state, props) {
      const { licenseUsageReports, filter, pages, count } = props;
      state.licenseUsageReports = licenseUsageReports;
      state.licenseUsageReportsCount = count;
      state.licenseUsageReportsFilter = filter;
      state.licenseUsageReportsPages = pages;
      return state;
    },
    setUserActivityReports(
      state,
      { userActivityReports, filter, pages, count },
    ) {
      state.userActivityReports = userActivityReports;
      state.userActivityReportsCount = count;
      state.userActivityReportsFilter = filter;
      state.userActivityReportsPages = pages;
      return state;
    },
    setGroupActivityReports(
      state,
      { groupActivityReports, filter, pages, count },
    ) {
      state.groupActivityReports = groupActivityReports;
      state.groupActivityReportsCount = count;
      state.groupActivityReportsFilter = filter;
      state.groupActivityReportsPages = pages;
      return state;
    },
    setAccountActivityReports(
      state,
      { accountActivityReports, filter, pages, count },
    ) {
      state.accountActivityReports = accountActivityReports;
      state.accountActivityReportsCount = count;
      state.accountActivityReportsFilter = filter;
      state.accountActivityReportsPages = pages;
      return state;
    },
  },
  effects: {
    async fetchLicenseUsageReport(payload, rootState) {
      try {
        const startDate = getStartDate(rootState); // current state of startDate - it is same as rootState.reports.startDate
        const endDate = getEndDate(rootState);
        const response = await reportApi.getLicenseUsageReport({
          organization: payload,
          startDate,
          endDate,
          fields:
            'name,avatarUrl,userCount,totalOrganizationLicense,licensePurchasedCount,licenseKickoffDate',
          limit: 9999,
        });

        this.setLicenseUsageReports({
          licenseUsageReports: response,
          pages: 1,
          count: response.length,
        });
      } catch (err) {
        handleError(err);
      }
    },
    async fetchUserActivityReport(payload, rootState) {
      try {
        const startDate = getStartDate(rootState);
        const endDate = getEndDate(rootState);
        const excludeDatabookUsers = getExcludeDatabookUsers(rootState);

        const response = await reportApi.getUserActivityReport({
          organization: payload,
          startDate,
          endDate,
          excludeDatabookUsers,
        });

        this.setUserActivityReports({
          userActivityReports: response,
          pages: 1,
          count: response.length,
        });
      } catch (err) {
        handleError(err);
      }
    },
    async fetchGroupActivityReport(payload, rootState) {
      try {
        const startDate = getStartDate(rootState);
        const endDate = getEndDate(rootState);
        const excludeDatabookUsers = getExcludeDatabookUsers(rootState);

        const response = await reportApi.getGroupActivityReport({
          organization: payload,
          startDate,
          endDate,
          excludeDatabookUsers,
        });

        this.setGroupActivityReports({
          groupActivityReports: response,
          pages: 1,
          count: response.length,
        });
      } catch (err) {
        handleError(err);
      }
    },
    async fetchAccountActivityReport(payload, rootState) {
      try {
        const startDate = getStartDate(rootState);
        const endDate = getEndDate(rootState);
        const excludeDatabookUsers = getExcludeDatabookUsers(rootState);

        const response = await reportApi.getAccountActivityReport({
          organization: payload,
          startDate,
          endDate,
          excludeDatabookUsers,
        });

        this.setAccountActivityReports({
          accountActivityReports: response,
          pages: 1,
          count: response.length,
        });
      } catch (err) {
        handleError(err);
      }
    },
  },
};

/** ********** Dispatchers *********** */

export const setOrganization = d => d.reports.setOrganization;
export const setStartDate = d => d.reports.setStartDate;
export const setEndDate = d => d.reports.setEndDate;
export const setExcludeDatabookUsers = d => d.reports.setExcludeDatabookUsers;

export const fetchLicenseUsageReport = d => d.reports.fetchLicenseUsageReport;
export const fetchUserActivityReport = d => d.reports.fetchUserActivityReport;
export const fetchGroupActivityReport = d => d.reports.fetchGroupActivityReport;
export const fetchAccountActivityReport = d =>
  d.reports.fetchAccountActivityReport;

/** ********** Selectors *********** */
export const getOrganization = s => s.reports.organization;
export const getStartDate = s => s.reports.startDate;
export const getEndDate = s => s.reports.endDate;
export const getExcludeDatabookUsers = s => s.reports.excludeDatabookUsers;

export const getLicenseUsageReport = s => s.reports.licenseUsageReports;
export const getLicenseUsageReportCount = s =>
  s.reports.licenseUsageReportsCount;
export const getLicenseUsageReportsPages = s =>
  s.reports.licenseUsageReportsPages;
export const getLicenseUsageReportFilter = s =>
  s.reports.licenseUsageReportFilter;

export const getUserActivityReport = s => s.reports.userActivityReports;
export const getUserActivityReportCount = s =>
  s.reports.userActivityReportsCount;
export const getUserActivityReportsPages = s =>
  s.reports.userActivityReportsPages;
export const getUserActivityReportFilter = s =>
  s.reports.userActivityReportFilter;

export const getGroupActivityReport = s => s.reports.groupActivityReports;
export const getGroupActivityReportCount = s =>
  s.reports.groupActivityReportCount;
export const getGroupActivityReportPages = s =>
  s.reports.groupActivityReportPages;
export const getGroupActivityReportFilter = s =>
  s.reports.groupActivityReportFilter;

export const getAccountActivityReport = s => s.reports.accountActivityReports;
export const getAccountActivityReportCount = s =>
  s.reports.accountActivityReportCount;
export const getAccountActivityReportPages = s =>
  s.reports.accountActivityReportPages;
export const getAccountActivityReportFilter = s =>
  s.reports.accountActivityReportFilter;

export const isLoadingLicenseUsageReports = s =>
  s.loading.effects.reports.fetchLicenseUsageReport;
export const isLoadingUserActivityReports = s =>
  s.loading.effects.reports.fetchUserActivityReport;
export const isLoadingGroupActivityReports = s =>
  s.loading.effects.reports.fetchGroupActivityReport;
export const isLoadingAccountActivityReports = s =>
  s.loading.effects.reports.fetchAccountActivityReport;
