import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { userUtils } from 'shared/utils';
import _ from 'lodash';
import config from 'config/navigation';
import LogoutIcon from 'mdi-react/LogoutIcon';
import clsx from 'clsx';
import * as auth from 'auth';
import * as User from 'models/user';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { EntitlementString } from 'shared/utils/entitlement';
import NavLink from './nav-link';
import Toolbar from './toolbar';
import HelpLinks from './help-links';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100vh',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 54,
  },
  menuVisible: {
    overflowY: 'visible',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2, 3),
    boxSizing: 'border-box',
    width: `calc(100% - ${drawerWidth}px)`,
    overflow: 'auto',
  },
  iconRoot: {
    minWidth: 40,
    '& svg': {
      width: 24,
      height: 24,
      '& path': {
        fill: theme.color.iconColorGray,
      },
    },
  },
  navigationItem: {
    borderRadius: '0 66px 66px 0',
  },
  menus: {
    width: drawerWidth,
    backgroundColor: theme.palette.background.paper,
    borderRight: `1px solid ${theme.palette.action.disabledBackground}`,
  },
  active: {
    backgroundColor: theme.palette.grayHover,
  },
}));

const buildMenu = (
  user,
  item,
  onClick,
  openMenus,
  classes,
  highlightIntermediate,
  nesting = 0,
) => {
  const {
    requiredEntitlement,
    label,
    icon,
    submenu,
    fullPath,
    newTab,
    highlight,
  } = item;
  const isOpen = openMenus[label];
  if (
    requiredEntitlement &&
    !userUtils.hasEntitlement(user, new EntitlementString(requiredEntitlement))
  ) {
    return;
  }

  if (!submenu) {
    return (
      <NavLink
        key={fullPath}
        primary={label}
        to={fullPath}
        icon={icon}
        nesting={nesting}
        newTab={newTab}
        highlight={highlight}
      />
    );
  }

  return (
    <div key={label}>
      <ListItem
        button
        onClick={() => onClick(label)}
        className={clsx({
          [classes.active]:
            highlightIntermediate &&
            window.location.pathname.includes(fullPath),
        })}
        classes={{
          root: classes.navigationItem,
        }}
      >
        {icon && (
          <ListItemIcon classes={{ root: classes.iconRoot }}>
            {icon}
          </ListItemIcon>
        )}
        <ListItemText
          primary={label}
          style={{ paddingLeft: nesting ? 40 + (nesting - 1) * 15 : 0 }}
        />
        {isOpen ? (
          <ExpandLess fontSize='small' />
        ) : (
          <ExpandMore fontSize='small' />
        )}
      </ListItem>
      <Collapse in={isOpen} timeout='auto' unmountOnExit>
        <List component='div' disablePadding dense>
          {submenu.map(subItem =>
            buildMenu(
              user,
              subItem,
              onClick,
              openMenus,
              classes,
              false,
              nesting + 1,
            ),
          )}
        </List>
      </Collapse>
    </div>
  );
};

const getOpenedMenus = (items, routes, submenus) => {
  const searched = routes.shift();
  const menuItem = items.find(item => item.route === searched);

  if (menuItem && menuItem.submenu) {
    submenus[menuItem.label] = true;
    return getOpenedMenus(menuItem.submenu, routes, submenus);
  }

  return submenus;
};

const Navigation = ({ children, user }) => {
  const classes = useStyles();
  const isIntentPage = useRouteMatch('/intent-dashboard');
  const [open, setOpen] = useState(true);
  const [hovered, setHover] = useState(false);
  const [openMenus, setOpenMenus] = useState({});

  useEffect(() => {
    const routes = window.location.pathname
      .split('/')
      .filter(route => !_.isEmpty(route));
    const opened = {};

    if (routes.length) {
      setOpenMenus(getOpenedMenus(config, routes, opened));
    }
  }, []);

  const toggleDrawer = () => {
    setOpen(prev => !prev);
    setHover(false);
  };

  const handleLogout = () => {
    auth.logout();
  };

  const onClick = labelToToggle => {
    setOpenMenus({ ...openMenus, [labelToToggle]: !openMenus[labelToToggle] });
  };

  const handleHover = () => {
    setHover(true);
  };

  const handleBlur = () => {
    setHover(false);
  };

  if (!user) {
    return (
      <div className={classes.root}>
        <main className={classes.content}>{children}</main>
      </div>
    );
  }

  if (user && isIntentPage) {
    return children;
  }

  return (
    <div className={classes.root}>
      <Drawer
        variant='permanent'
        open={open}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
            [classes.menuVisible]: hovered,
          }),
        }}
      >
        <Toolbar user={user} onClick={toggleDrawer} open={open} />
        <div
          className={classes.menus}
          onMouseEnter={!open ? handleHover : undefined}
          onMouseLeave={!open ? handleBlur : undefined}
        >
          <Divider />
          <List>
            {config.map(item =>
              buildMenu(
                user,
                item,
                onClick,
                open || hovered ? openMenus : {},
                classes,
                !open && !hovered,
              ),
            )}
          </List>
          <Divider />
          <List>
            <ListItem
              button
              onClick={handleLogout}
              classes={{
                root: classes.navigationItem,
              }}
            >
              <ListItemIcon classes={{ root: classes.iconRoot }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary='Logout' />
            </ListItem>
          </List>
          <Divider />
          <HelpLinks />
        </div>
      </Drawer>
      <main className={classes.content}>{children}</main>
    </div>
  );
};

Navigation.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object,
};

const mapStateToProps = state => ({
  user: User.getUser(state),
});

export default connect(mapStateToProps)(Navigation);
